import React from 'react';

import { Collapse } from 'antd';

const { Panel } = Collapse;

const AppFaq = () => {

    const items = [
        {
            question: "HOW CAN WE TRUST YOU?",
            answer: "All the games you buy are invoiced with the e-invoice system and transferred to you via e-mail on the day of the purchase.",
            key: 1
        },
        {
            question: "Will the products we purchase belong to us?",
            answer: "Since the products you buy are published with a unique game/application ID, they remain yours for life. You can use our add-remove or update services as long as you want.",
            key: 2
        },
        {
            question: "ARE STORE FEES ALSO INCLUDED IN THE PACKAGE PERFECT?",
            answer: "It may be included depending on the package you choose. It is included in the packages that write \"Google Play Account\".",
            key: 3
        },
        {
            question: "WHEN ARE THE PRODUCTS I PURCHASED DELIVERED?",
            answer: "The products you have purchased are installed within 3 working days at the latest and transferred to your accounts (without your password).",
            key: 4
        },
        {
            question: "I HAVE DIFFICULTY IN GAME SELECTION, WHAT SHOULD I DO?",
            answer: "Together with our consultant team, you can choose the most suitable game for you or get support on the best choice among the games you are undecided about.",
            key: 5
        },
        {
            question: "WHAT DOES THE CONSULTANCY IN THE PACKAGES INCLUDE?",
            answer: "Our consultancy service will be with you in all the procedures you need to do during the whole process. Google Play Account opening, Abmod Unity Account opening, publishing the application in the store, Advertising guidance will be in contact with you and assist you in all steps.",
            key: 6
        },
        {
            question: "HOW CAN I MAKE PROMOTION AND ADVERTISING?",
            answer: "It includes consultancy, advertising and promotion guidance. The way to be followed for promotion is explained in detail. You can easily promote and appeal to more audiences by reducing your advertising budget.If you don't want to deal with it, we'll do it for you by choosing our advertising service packages. (We also prepare the images for the advertisement.) ",
            key: 7
        },
        {
            question: "CAN I CREATE MY OWN PACKAGE?",
            answer: "Of course. You can determine your own package according to the services you want and get a price by contacting us.",
            key: 8
        },
        {
            question: "DO YOU RECEIVE A COMMISSION FROM GAME INCOME?",
            answer: "Our company does not demand any commission from game income.",
            key: 9
        },
        {
            question: "DO THE GAMES WE PURCHASE HAVE A LICENSE PERIOD?",
            answer: "The product license you purchase is unlimited.",
            key: 10
        },
        {
            question: "WHAT DOES THE CONSULTANCY IN THE PACKAGES INCLUDE?",
            answer: "Our consultancy service will be with you in all the procedures you need to do during the whole process. Google Play Account opening, Abmod Unity Account opening, publishing the application in the store, Advertising guidance will be in contact with you and assist you in all steps.",
            key: 11
        },
        {
            question: "I DON'T WANT TO BUY PACKAGE. I JUST NEED A CERTAIN SERVICE. IS IT POSSIBLE?",
            answer: "Yes. Special service will be provided to you. Contact us.",
            key: 12
        },
        {
            question: "What happens after purchase?",
            answer: "A team member will contact you, then " +
                " Our team member will ask you for the name you will set for your game." +
                " With the guidance of our teammates, you integrate the game name you have decided into your Google AdMob network." +
                " You add your requested ad networks to your game, which you have integrated into your Google AdMob network, and report your ad codes to our teammates." +
                " Your AdMob codes, together with the license, graphics and sounds that will be completely unique to you, are combined and taken as a game file." +
                " The game, which has passed your approval, is now ready to be published and transferred to" +
                " your Google Play Store and/ or App Store without a password from you." +
                "You can start earning right after the transferred game is released.",
            key: 13
        }
    ]
    return (
        <div id="faq" className="block faqBlock bgGreen">
            <div className="container-fluid">
                <div className="titleHolder">
                    <h2>Frequently Asked Questions</h2>
                    <p>Quidem reiciendis iure, aperiam blanditiis. Alias esse, nam, ea quam</p>
                </div>
                <Collapse defaultActiveKey={['1']}>
                    {
                        items.map(item => {
                            return (
                                <Panel header={item?.question.charAt(0).toUpperCase() + item?.question.slice(1).toLowerCase()} key={item?.key}>
                                    <p>{item?.answer}</p>
                                </Panel>
                            );
                        })
                    }
                </Collapse>
            </div>
        </div>
    );
}

export default AppFaq;
import React from 'react'
import { Row, Typography, Col, Image , BackTop} from 'antd';
import { FaInstagram, FaYoutube, FaWhatsapp } from "react-icons/fa";
import image from "../../assets/images/skaimmsoft.png";

const AppFooter = () => {
    return (
        <div className="container-fluid">
            <div className="footer">
                <Row style={{ justifyContent: "center", marginBottom: "20px" }}>
                    <Col>
                        <Image
                            preview={false}
                            width={50}
                            height={50}
                            src={image}
                        />
                    </Col>
                    <Col>
                        <Typography.Title
                            level={4}
                            style={{
                                fontSize: "28px",
                                margin: 0,
                                color: 'white',
                                background: "black",
                                paddingLeft: "10px",
                                paddingTop: "5px",

                            }}
                        >
                            SkaimmSoft
                        </Typography.Title>
                    </Col>
                </Row>
                <ul className="socials">
                    <li><a href="https://www.youtube.com/channel/UC1AhYtqmFE2rvcK_Do1_elw"><FaYoutube /></a></li>
                    <li><a href="https://www.instagram.com/skaimmsoft"><FaInstagram /></a></li>
                    <li><a href="https://wa.me/905533113727"><FaWhatsapp /></a></li>
                </ul>
                <div className="copyright">Copyright &copy; 2020 SkaimmSoft</div>
                <BackTop>
                    <div className="goTop"><i className="fas fa-arrow-circle-up"></i></div>
                </BackTop>
            </div>
        </div >
    );
}

export default AppFooter

import React from 'react';
import './App.less';
import "antd/dist/antd.less";

import { Layout } from 'antd';
import AppHeader from './components/common/AppHeader';
import AppFooter from './components/common/AppFooter';
import AppHome from './views/AppHome';
const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <Layout className="mainLayout">
      <Header>
        <AppHeader />
      </Header>
      <Content>
        <AppHome />
      </Content>
      <Footer>
        <AppFooter />
      </Footer>
    </Layout>
  )
}
export default App;

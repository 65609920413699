import React from 'react';
import { Button, Anchor, Carousel, Typography } from 'antd';
import image from "../../assets/images/skaimmsoft.png";

const { Link } = Anchor;

const items = [
    {
        key: '1',
        title: 'A mobile game that belongs to you',
        content: 'It has never been so easy to have your own mobile game without knowing coding. We do all the processes for you or help you through the whole process, from the installation of the game to its release in the store. We also do not ask for a commission from your earnings. you will only count your income :)',
        uri: image
    },
    {
        key: '2',
        title: 'The software of your potential',
        content: "You will take your place in the software world with your game and you will enjoy the passive income that is everyone's dream. Obtain your financial freedom",
        quotes: "“The greater the passive income you can build, the freer you will become.”",
        writer: "- Todd M. Fleming"

    },
    {
        key: '3',
        title: 'Changing one’s life by altering one’s software',
        content: 'We prepare your software with special consultancy, fast delivery and game license and provide all the conveniences for you to take your place in the software world by managing the processes.',
    },
]

const AppHero = () => {
    return (
        <div id="hero" className="heroBlock">
            <Carousel autoplay>
                {items.map(item => {
                    return (
                        <div key={item.key} className="container-fluid">
                            <div className="content">
                                <div style={{ marginBottom: "90px", maxHeight: "50px", display: "flex" }}>
                                    <img src={image} alt="" width={100} height={100} />
                                    <Typography.Title
                                        level={4}
                                        style={{
                                            fontSize: "48px",
                                            margin: 0,
                                            color: 'indigo',
                                            paddingLeft: "20px",
                                            paddingTop: "15px",

                                        }}
                                    >
                                        Skaimm
                                    </Typography.Title>
                                </div>
                                <h3>{item.title}</h3>
                                <p>{item.content}</p>
                                {item.quotes && <>
                                    <p>{item.quotes}</p>
                                    <p>{item.writer}</p>
                                </>}
                                <div style={{ maxHeight: "50px", display: "flex" }}>
                                    <Anchor affix={false} targetOffset="65" >
                                        <Link href="#pacpro" title={<Button type="primary" size="large">Go to Store</Button>} />
                                    </Anchor>
                                    <Anchor affix={false} targetOffset="65" >
                                        <Link href="#contact" title={<Button className="learn-button" size="large" style={{ background: "#f7f7f7" }}>Learn More</Button>} />
                                    </Anchor>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}

export default AppHero;
import React from 'react';

import image1 from '../../assets/images/mobile.svg';
import image2 from '../../assets/images/ads.svg';
import image3 from '../../assets/images/consulate.svg';

import { Row, Col, Card } from 'antd';
const { Meta } = Card;

const AppService = () => {
    return (
        <div id="services" className="block featureBlock bgGray">
            <div className="container-fluid">
                <div className="titleHolder">
                    <h2>Services</h2>
                </div>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                        <Card
                            cover={<img alt="Mobile Applications" src={image1} />}
                        >
                            <Meta title="Mobile Applications" />
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                        <Card
                            cover={<img alt="Advertising" src={image2} />}
                        >
                            <Meta title="Advertising" />
                        </Card>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                        <Card
                            cover={<img alt="Consulting" src={image3} />}
                        >
                            <Meta title="Consulting" color="white" />
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default AppService;
import React from 'react';

import { Form, Input, Button, Checkbox, Select } from 'antd';
import { FaWhatsapp } from "react-icons/fa";
import { timeZones } from './Timezone';
const { TextArea } = Input;

const AppContact = () => {
    const [form] = Form.useForm();

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select style={{ width: 200, background: "#f7f7f7" }} defaultValue={"Country Code"}>
                {timeZones.map((tm) => {
                    return <Select.Option value={tm?.name + " " + tm?.mobileCode}>{tm?.name} ({tm?.mobileCode})</Select.Option>
                })}
            </Select>
        </Form.Item>
    );

    const getBody = () => {
        return form.getFieldValue("message") + "%0D%0A%0D%0A%0D%0A" + form.getFieldValue("fullname") + "%0D%0A" +
            form.getFieldValue("prefix") + form.getFieldValue("phone") + "%0D%0A" + form.getFieldValue("email");
    }

    return (
        <div id="contact" className="block contactBlock bgGreen">
            <div className="container-fluid">
                <div className="titleHolder">
                    <h2>Get in Touch</h2>
                    <Button className="wp-button" href='https://wa.me/message/N2RWSSIHH2FOK1' style={{ background: "#25D366" }}>
                        <div style={{ color: "white", justifyContent: "center", display: "flex" }} >
                            <FaWhatsapp color='white' style={{ width: "25px", height: "25px", marginTop: "2px" }} />
                            <div style={{ marginTop: "2px", marginLeft: "3px" }}> WhatsApp Quick Help</div>
                        </div>
                    </Button>
                </div>
           {/*      <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    form={form}
                >
                    <Form.Item
                        name="fullname"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your full name!'
                            }]
                        }
                    >
                        <Input className="form-input" placeholder="Full Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                            {
                                required: true,
                                message: 'Please input your E-mail!',
                            },
                        ]}
                    >
                        <Input className="form-input" placeholder="Email Address" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                        <Input className="form-input" addonBefore={prefixSelector} style={{ width: '100%', background: "#dae3d0" }} placeholder="Phone Number" />
                    </Form.Item>
                    <Form.Item
                        name="subject"
                    >
                        <Input className="form-input" placeholder="Subject" />
                    </Form.Item>
                    <Form.Item
                        name="message"
                    >
                        <TextArea className="form-input" placeholder="Message" />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "center" }}>
                        <Form.Item
                            name="remember"
                            noStyle
                            rules={[
                                { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Should accept agreement') },
                            ]}
                        >
                            <Checkbox>I agree with terms and conditions.</Checkbox>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item shouldUpdate style={{ textAlign: "center" }}>
                        {() => (
                            <Button
                                type="primary"
                                disabled={
                                    !form.isFieldsTouched(true) ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                }
                                href={`mailto:info@skaimmsoft.com?subject=${form.getFieldValue("subject")}&body=${getBody()}`}
                            >
                                Send Email
                            </Button>
                        )}
                    </Form.Item>
                </Form> */}
            </div>
        </div>
    );
}

export default AppContact;
import React, { useState } from 'react';

import { Anchor, Drawer, Button, Row, Col, Image, Typography } from 'antd';
import image from "../../assets/images/skaimmsoftlight.png";
import { MdOutlineMenu } from "react-icons/md";

const { Link } = Anchor;

function AppHeader() {
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <div className="container-fluid">
            <div className="header">
                <Row style={{ justifyContent: "center" }}>
                    <Col>
                        <Image
                            preview={false}
                            width={40}
                            height={40}
                            src={image}
                        />
                    </Col>
                    <Col>
                        <Typography.Title
                            level={4}
                            style={{
                                fontSize: "22px",
                                margin: 0,
                                color: 'indigo',
                                paddingLeft: "10px",
                                paddingTop: "5px",

                            }}
                        >
                            SkaimmSoft
                        </Typography.Title>
                    </Col>
                </Row>
                <div className="mobileHidden">
                    <Anchor targetOffset="65">
                        <Link href="#hero" title="Home" />
                        <Link href="#pacpro" title="Packages & Products" />
                        <Link href="#faq" title="FAQ" />
                        <Link href="#about" title="About" />
                        <Link href="#contact" title="Contact" />
                        {/* <Link href="#affiliate" title="Affiliate Marketing" /> */}
                        <Link href="#services" title="Services" />
                    </Anchor>
                </div>
                <div className="mobileVisible">
                    <Button type="primary" onClick={showDrawer}>
                        <MdOutlineMenu style={{ width: "25px", height: "25px" }} />
                    </Button>
                    <Drawer
                        placement="right"
                        closable={false}
                        onClose={onClose}
                        visible={visible}
                    >
                        <Anchor targetOffset="65">
                            <Link href="#hero" title="Home" />
                            <Link href="#pacpro" title="Packages & Products" />
                            <Link href="#faq" title="FAQ" />
                            <Link href="#about" title="About" />
                            <Link href="#contact" title="Contact" />
                            <Link href="#services" title="Services" />
                        </Anchor>
                    </Drawer>
                </div>
            </div>
        </div>
    );
}

export default AppHeader;
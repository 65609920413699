import React, { useEffect } from 'react';

import { Row, Col } from 'antd';
import { analytics } from '../../firebase.config';

const items = [
    {
        key: '1',
        icon: <i className="fas fa-desktop"></i>,
        title: 'Special Advisor',
        content: 'Finding quick solutions to problems and speeding up processes with a personal consultant.',
    },
    {
        key: '2',
        icon: <i className="fas fa-database"></i>,
        title: 'On Time Delivery',
        content: 'Every purchased package is delivered within 5 working days at the latest.',
    },
    {
        key: '3',
        icon: <i className="fas fa-chart-pie"></i>,
        title: 'License',
        content: 'The license of each delivered game belongs to you unlimitedly.',
    },
]

const AppAbout = () => {
    
    return (
        <div id="about" className="block aboutBlock bgGray">
            <div className="container-fluid">
                <div className="titleHolder">
                    <h2>About Us</h2>
                    <p>Committed to a better future and greater liberty</p>
                </div>
                <div className="contentHolder">
                    <p>Skaimm Tech is a software development company that builds mobile applications for iOS and Android-based smartphones and tablets.Our service lines include software and digital strategy consulting, development and managed application support.</p>
                    <p>"We offer uninterrupted technical support for you to have your own mobile game without any coding knowledge and to gain high profits with your increasing user network.</p>
                </div>
                <Row className="about-item" gutter={[16, 16]}>
                    {items.map(item => {
                        return (
                            <Col md={{ span: 8 }} key={item.key}>
                                <div className="content">
                                    <div className="icon">
                                        {item.icon}
                                    </div>
                                    <h3 className='about-item-header'>{item.title}</h3>
                                    <p>{item.content}</p>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
}

export default AppAbout;
import React from 'react';
import AppAbout from '../components/home/AppAbout';
import AppContact from '../components/home/AppContact';
import AppFaq from '../components/home/AppFaq';
import AppHero from '../components/home/AppHero';
import AppService from '../components/home/AppService';
import AppPackage from '../components/home/AppPackage';
import AppAffiliate from '../components/home/AppAffiliate';


const AppHome = () => {
/* 
    useEffect(() => {
        analytics.logEvent("homepage_visited")
    }) */

    return (
        <div className="main">
            <AppHero />
            <AppPackage />
            <AppFaq />
            <AppAbout />
            <AppContact />
           {/*  <AppAffiliate /> */}
            <AppService />
        </div>
    );
}

export default AppHome;
import React, { useMemo, useState } from 'react';

import { List, Card, Button, Radio, Col, Row, Pagination, Checkbox, Select, Image, Typography } from 'antd';
import { games, packages } from '../../views/Data';
import _ from 'lodash';

const AppPackage = () => {
    // const [visible, setVisible] = useState(false);
    // const [selectedGame, setSelectedGame] = useState(null);
    // const [type, setType] = useState("bronze");
    // const [isGameType, setIsGameType] = useState(false);
    // const [gameType, setGameType] = useState("All");
    // const [gameSize, setGameSize] = useState(games?.length ? games?.length : 0);
    // const [page, setPage] = useState({ current: 1, pageSize: 8 })
    // const [imageList, setImageList] = useState([]);

    /*   const onPageChange = (current, pageSize) => {
          setPage({
              current: current,
              pageSize: pageSize
          })
      }; */

    /*  const gameTypeOption = useMemo(() => {
         let option = ["All"]
         _.forEach(games, (game) => {
             if (!option.includes(game?.gameType)) {
                 option.push(game?.gameType)
             }
         })
         return _.map(option, (o) => { return { value: o, label: o } });
     }, []) */

    const dataPackage = useMemo(() => {
        return _.filter(packages,
            { 'type': "other" }
        );
    }, [])

    /*  const dataPackageSize = useMemo(() => {
         let bronzeSize = (_.filter(packages,
             { 'type': "bronze" }
         )).length;
         let silverSize = (_.filter(packages,
             { 'type': "silver" }
         )).length;
         let goldSize = (_.filter(packages,
             { 'type': "gold" }
         )).length;
         let limitedSize = (_.filter(packages,
             { 'type': "limited" }
         )).length;
 
         return {
             bronze: bronzeSize,
             silver: silverSize,
             gold: goldSize,
             limited: limitedSize
         };
     }, [])
 
     const dataGames = useMemo(() => {
         let filteredByType = isGameType ? _.filter(games, 'show') : (_.filter(games,
             { 'type': type }
         ));
         console.log("gelen kac ", filteredByType)
         let filteredByGameType = gameType === "All" ? filteredByType : (_.filter(filteredByType,
             { 'gameType': gameType }
         ));
         setGameSize(filteredByGameType?.length ? filteredByGameType?.length : 0)
         return _.slice(filteredByGameType, page.pageSize * (page.current - 1), page.pageSize * page.current);
     }, [gameType, isGameType, page, type])
  */
    const title = (item) => {
        return <>
            <div className="nameText">{item?.name}</div>
        </>
    }

    const onClick = (item) => {
        // analytics.logEvent(`${item?.id}_package_clicked`)
        item?.iCode && window.open("https://iyzi.link/" + item?.iCode, '_blank', 'noopener,noreferrer')
    }

    return (
        <div id="pacpro" className="block pricingBlock bgGray">
            <div className="container-fluid">
                <div className="titleHolder">
                    <h2>Packages</h2>
                    <p>If packages don't fit your needs, contact us</p>
                </div>
                {/*  <div className="radioHolder">
                    <Radio.Group defaultValue="bronze" buttonStyle="solid" size="large">
                        <Radio.Button value="bronze" disabled={dataPackageSize.bronze === 0} onClick={() => setType("bronze")}>Bronze</Radio.Button>
                        <Radio.Button value="silver" disabled={dataPackageSize.silver === 0} onClick={() => setType("silver")}>Silver</Radio.Button>
                        <Radio.Button value="gold" disabled={dataPackageSize.gold === 0} onClick={() => setType("gold")}>Gold</Radio.Button>
                        {dataPackageSize.limited > 0 && <Radio.Button value="limited" disabled={dataPackageSize.limited === 0} onClick={() => setType("limited")}>Limited</Radio.Button>}
                    </Radio.Group>
                </div> */}
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        xxl: 1,
                    }}
                    dataSource={dataPackage}
                    renderItem={item => (
                        <List.Item
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Card title={title(item)} style={{ background: "#dae3d0", maxWidth: "500px" }}>
                                <Row style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                                    {item?.from && <Col style={{ fontSize: "24px", textDecoration: "line-through", color: 'grey', marginBottom: "5px" }}>
                                        {item?.from + "/"}
                                    </Col >}
                                    <Col style={{ fontSize: "28px", color: '#81659e' }}>
                                        {item?.cost}
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%", alignItems: "center", justifyContent: "center", minHeight: "250px" }}>
                                    <Row style={{ width: "100%", alignItems: "center", justifyContent: "center", flexDirection: 'column' }}>
                                        {_.map(item?.details, (detail) => {
                                            return (
                                                <Row style={{ marginBottom: "1px", fontSize: "16px", color: "#81659e" }}>{detail}</Row>
                                            )
                                        })}
                                    </Row>
                                </Row>

                                <Row style={{ width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                    <Button
                                        type="primary"
                                        style={{ width: "60%", alignItems: "center", justifyContent: "center", flexDirection: 'column' }}
                                        onClick={() => onClick(item)}
                                    > Buy Now
                                    </Button>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                    <p>Other expenses; Buying a Source Code, Open Account in Google Play Console and Social Media Promote
                        <br /> For other expenses, between 75 and 500 dollars according to your budget.</p>

                </div>
                {/*   <div className="titleHolder" style={{ flexDirection: "column" }}>
                    <h2>{!isGameType ? type.charAt(0).toUpperCase() + type.slice(1) + " Package Games" : "All Games"}</h2>
                    <Checkbox onChange={(x) => setIsGameType(x.target.checked)}>Show All Games</Checkbox>
                </div>
                <Row style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                </Row>
                <Row style={{ width: "100%", alignItems: "center", justifyContent: "center", marginBottom: "10px", color: "#81659e" }}>
                    {"Game Type : "}
                    <Select
                        defaultValue="All"
                        style={{
                            width: 120,
                            marginLeft: "10px"
                        }}
                        onChange={(value) => setGameType(value)}
                        options={gameTypeOption}
                    />
                </Row>
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 2,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    dataSource={dataGames}
                    renderItem={item => (
                        <List.Item>
                            <>
                                <Row style={{ alignItems: "center", justifyContent: "center" }}>
                                    <div style={{
                                        width: "200px", alignItems: "center", justifyContent: "center",
                                        background: `${item?.type === "bronze" ? "#d59352" : item?.type === "silver" ? "#c0c0c0" : item?.type === "gold" ? "#fad25a" : item?.type === "limited" ? "#006994" : "#dae3d0"}`
                                    }}>{item?.type !== "other" ? item?.type?.toUpperCase() : "Ask Price"}</div>
                                </Row>
                                <Image
                                    preview={{
                                        visible: false,
                                    }}
                                    width={200}
                                    height={300}
                                    src={item?.urls[0]}
                                    onClick={() => {
                                        //analytics.logEvent(`${item?.id}_game_clicked`)
                                        setVisible(true);
                                        setImageList(item?.urls);
                                        setSelectedGame(item)
                                    }}
                                />
                                <div
                                    style={{
                                        display: 'none',
                                    }}
                                >
                                    <Image.PreviewGroup
                                        preview={{
                                            visible,
                                            onVisibleChange: (vis) => setVisible(vis),
                                            countRender: () =>
                                                <Typography.Title
                                                    level={4}
                                                    style={{
                                                        margin: 0,
                                                        color: 'white',
                                                        background: "black",
                                                    }}
                                                >
                                                    {selectedGame?.name}
                                                </Typography.Title>,
                                        }}
                                    >
                                        {imageList.map((picSrc) => {
                                            return <Image src={picSrc} alt={picSrc} />
                                        })}
                                    </Image.PreviewGroup>
                                </div>
                            </>
                        </List.Item>
                    )}
                />

                <Row style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                    <Pagination
                        showSizeChanger
                        onShowSizeChange={onPageChange}
                        defaultCurrent={1}
                        total={gameSize}
                        onChange={onPageChange}
                        pageSizeOptions={[8, 16, 32, 64]}
                        defaultPageSize={8}
                    />
                </Row> */}
            </div>
        </div>
    );
}

export default AppPackage;